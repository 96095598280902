<template>
  <div>
    <b-card class="mb-1">
      <b-form class="m-0" ref="formFilter" @submit.prevent="getData()">
        <div class="form-row">
          <b-col cols="12" md="2">
            <div class="form-group">
              <label for="">CPF</label>
              <input type="tel" v-model="filters.cpf" class="form-control" />
            </div>
          </b-col>
          <b-col cols="12" md="2">
            <div class="form-group">
              <label for="">ID</label>
              <input type="tel" v-model="filters.id" class="form-control" />
            </div>
          </b-col>
          <b-col cols="12" md="2">
            <div class="form-group">
              <label for="">Cupom</label>
              <input type="tel" v-model="filters.coupon" class="form-control" />
            </div>
          </b-col>
          <b-col cols="12" md="3">
            <div class="form-group">
              <label for="">Status</label>
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                :options="optionsStatus"
                v-model="filters.status"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </b-col>
          <b-col cols="12" md="3">
            <div class="form-group">
              <label for="">Form. pagamento</label>
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                :options="optionsPaymentMethods"
                v-model="filters.method"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </b-col>
        </div>
        <div class="form-row">
          <b-col cols="12" md="3">
            <div class="form-group mb-md-0">
              <label for="">Período</label>
              <flat-pickr
                v-model="filters.rangeDate"
                class="form-control"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </b-col>
          <b-col cols="12" md="5">
            <div class="form-group mb-md-0">
              <label for="">Produto</label>
              <v-select
                ref="selectProduct"
                label="title"
                item-text="title"
                item-value="code"
                v-model="filters.product"
                placeholder="Digite o título"
                :options="optionsProducts"
                @search="fetchProducts"
              >
                <span
                  slot="no-options"
                  @click="$refs.selectProduct.open = false"
                >
                  Nenhum registro encontrado
                </span>
              </v-select>
            </div>
          </b-col>

          <b-col cols="12" md="3">
            <div class="form-group mb-md-0">
              <label for="">Categoria do produto</label>
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                :searchable="false"
                :options="optionsProductCategories"
                v-model="filters.category"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </b-col>
          <b-col cols="12" md="1">
            <button
              type="submit"
              class="btn btn-primary btn-block"
              style="margin-top: 20px"
            >
              <div v-if="submitedFilter">
                <b-spinner label="Loading..." small />
              </div>
              <div v-else>
                <feather-icon
                  icon="SearchIcon"
                  class="cursor-pointer cursor"
                  size="16"
                />
              </div>
            </button>
          </b-col>
        </div>
      </b-form>
    </b-card>

    <b-card no-body v-if="!submitedFilter" class="mb-0">
      <div class="m-2">
        <b-form-row class="justify-content-between">
          <b-col
            cols="4"
            md="2"
            class="d-none d-sm-block align-items-center justify-content-left"
          >
            <v-select
              label="title"
              required
              v-model="filters.perPage"
              :options="filters.perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </b-col>
          <b-col cols="12" md="4" offset="md-4">
            <b-form-input
              type="search"
              class="d-inline-block"
              placeholder="Pesquisar..."
              id="filterInput"
              v-model="filter"
            />
          </b-col>
        </b-form-row>
      </div>

      <b-table
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="fields"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(customer_name)="data">
          <b-media vertical-align="center">
            <span class="d-block text-nowrap">
              {{ data.item.customer_name }}
            </span>
            <p class="mb-0" style="margin-top: 4px">
              <strong><i class="bi bi-credit-card-2-front"></i></strong>
              {{ data.item.customer_cpf }} -
              <strong class="text-purple">
                <i class="bi bi-box"></i> {{ data.item.items }}
              </strong>
              <b-badge
                class="ml-1"
                v-if="data.item.origin_name"
                variant="light-dark"
              >
                {{ data.item.origin_name }}
              </b-badge>
            </p>
          </b-media>
        </template>
        <template #cell(payment_method)="data">
          <i
            style="font-size: 1.3rem"
            :class="`bi ${data.item.payment_method_icon}`"
          ></i>
        </template>
        <template #cell(status_name)="data">
          <b-badge class="d-block" :variant="`light-${data.item.status_class}`">
            {{ data.item.status_name }}
          </b-badge>
        </template>
        <template #cell(total)="data">
          {{ data.item.total | toCurrency }}
        </template>
        <template #cell(total_discount)="data">
          <b-badge
            v-if="data.item.total_discount"
            class="d-block"
            variant="light-success"
          >
            {{ data.item.total_discount | toCurrency }}
          </b-badge>
        </template>
        <template #cell(action)="data">
          <router-link
            :id="`product-${data.item.id}-preview-icon`"
            :to="{
              name: 'transactions-orders-preview',
              params: { uuid: data.item.uuid },
            }"
            class="d-flex align-items-center"
            v-if="$can('Pedido - Visualizar', 'Comercial')"
          >
            <feather-icon
              icon="EyeIcon"
              size="16"
              class="cursor-pointer cursor"
            />
          </router-link>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="filters.currentPage"
        :per-page="filters.perPage"
        :total-rows="totalRows"
      />
    </b-card>

    <b-card v-else class="mb-0">
      <div class="row">
        <div class="col-md-12 text-center">
          <p class="my-0 py-3 d-flex-center">
            <b-spinner small variant="primary" class="mr-50" /> Buscando
            pedidos...
          </p>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BIcon,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BTable,
  BButton,
  BPagination,
  BMedia,
  BTooltip,
  BBadge,
  BFormRow,
  BSpinner,
} from "bootstrap-vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  components: {
    flatPickr,
    CustomPaginateTable,
    BCard,
    BFormRow,
    BForm,
    BIcon,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BTable,
    BButton,
    BPagination,
    BMedia,
    BTooltip,
    BBadge,
    vSelect,
  },
  data() {
    return {
      filters: {
        rangeDate: `${moment()
          .subtract("1", "week")
          .format("YYYY-MM-DD")} até ${moment().format("YYYY-MM-DD")}`,
        status: "",
        method: "",
        cpf: "",
        product: "",
        id: "",
        categories: "",
        currentPage: 1,
        perPage: 100,
      },
      submitedFilter: false,
      filter: null,
      filterOn: [],
      totalRows: 0,
      rowsTable: 0,
      dataItems: [],
      fields: [
        {
          key: "id",
          label: "ID",
          class: "text-left",
        },
        {
          key: "customer_name",
          label: "Cliente",
          class: "text-left",
        },
        {
          key: "status_name",
          label: "Status",
          class: "text-center",
        },
        {
          key: "payment_method",
          label: "Forma",
          class: "text-center",
        },
        {
          key: "total",
          label: "total",
          class: "text-center",
        },
        {
          key: "created_at",
          label: "Data",
          class: "text-center",
        },
        {
          key: "action",
          label: "",
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      optionsPaymentMethods: [],
      optionsStatus: [],
      optionsProducts: [],
      optionsProductCategories: [],
    };
  },
  methods: {
    async fetchProductCategories() {
      this.optionsProductCategories = [];
      this.$store.dispatch("Product/categories").then((res) => {
        this.optionsProductCategories = res.data;
      });
    },
    async fetchProducts(term) {
      this.optionsProducts = [];
      if (term.length > 2) {
        this.$store.dispatch("Product/search", { term: term }).then((res) => {
          this.optionsProducts = res;
        });
      }
    },
    async fetchStatus() {
      this.$store.dispatch("Order/status").then((res) => {
        this.optionsStatus = res;
      });
    },
    async fetchPaymentMethods() {
      this.$store.dispatch("Order/paymentMethods").then((res) => {
        this.optionsPaymentMethods = res;
      });
    },
    submitFilter() {
      this.getData();
    },
    setPerPageSelected(obj) {
      this.filters.perPage = obj.value;
    },
    onFiltered(filteredItems) {
      this.metaTotal = filteredItems.length;
      this.filters.currentPage = 1;
    },
    onFiltered(filteredItems) {
      this.metaTotal = filteredItems.length;
      this.filters.currentPage = 1;
    },
    async getData() {
      this.submitedFilter = true;

      let filtersSearch = {
        rangeDate: this.filters.rangeDate ?? "",
        status: this.filters.status ? this.filters.status.code : "",
        method: this.filters.method ? this.filters.method.code : "",
        product: this.filters.product ? this.filters.product.code : "",
        coupon: this.filters.coupon ? this.filters.coupon.code : "",
        category: this.filters.category ? this.filters.category.code : "",
        cpf: this.filters.cpf ?? "",
        id: this.filters.id ?? "",
        currentPage: this.filters.currentPage,
        perPage: this.filters.perPage,
      };

      this.$store
        .dispatch("Order/fetchAllCancelled", filtersSearch)
        .then((orders) => {
          if (orders) {
            this.dataItems = orders.data;
            this.rowsTable = orders.data.length;
            this.totalRows = orders.meta.total;
            this.filters.currentPage = orders.meta.current_page;
          }
        })
        .finally(() => {
          this.submitedFilter = false;
        });
    },
    methodIcon(item) {
      switch (item) {
        case 1:
          return "credit-card";
        case 2:
          return "upc-scan";
        case 3:
          return "qr-code-scan";
        default:
          return "arrow-down-up";
      }
    },
  },
  directives: {
    Ripple,
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
    this.$root.$off("open:export-table");
  },
  mounted() {
    this.getData();
    this.fetchProductCategories();
    this.fetchStatus();
    this.fetchPaymentMethods();

    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.filters.perPage = res._perPage;
      this.getData();
    });

    this.$root.$on("open:export-table", () => {
      this.$swal({
        title: "Exportar Pedidos",
        text: "Enviaremos para o seu e-mail e WhatsApp, o link para você baixar o relatório de pedidos.",
        iconHtml: '<i class="bi bi-download"></i>',
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Gerar relatório",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("Order/export", {
              filters: {
                rangeDate: this.filters.rangeDate ?? "",
                status: this.filters.status ? this.filters.status.code : "",
                method: this.filters.method ? this.filters.method.code : "",
                product: this.filters.product ? this.filters.product.code : "",
                coupon: this.filters.coupon ? this.filters.coupon.code : "",
                category: this.filters.category
                  ? this.filters.category.code
                  : "",
                cpf: this.filters.cpf ?? "",
                id: this.filters.coupon ?? "",
              },
            })
            .then((res) => {
              console.log(res);
            })
            .finally(() => {
              this.submitedFilter = false;
            });
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.swal2-icon-content {
  i {
    font-size: 2rem;
    color: #28c76f;
  }
}

.swal2-icon-show {
  border-color: #28c76f;
}
</style>
