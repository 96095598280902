var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-1" },
        [
          _c(
            "b-form",
            {
              ref: "formFilter",
              staticClass: "m-0",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.getData()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c("b-col", { attrs: { cols: "12", md: "2" } }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("CPF")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filters.cpf,
                            expression: "filters.cpf",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "tel" },
                        domProps: { value: _vm.filters.cpf },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.filters, "cpf", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "2" } }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("ID")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filters.id,
                            expression: "filters.id",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "tel" },
                        domProps: { value: _vm.filters.id },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.filters, "id", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "2" } }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Cupom")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filters.coupon,
                            expression: "filters.coupon",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "tel" },
                        domProps: { value: _vm.filters.coupon },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.filters, "coupon", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Status")]),
                        _c(
                          "v-select",
                          {
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              options: _vm.optionsStatus,
                            },
                            model: {
                              value: _vm.filters.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "status", $$v)
                              },
                              expression: "filters.status",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Form. pagamento"),
                        ]),
                        _c(
                          "v-select",
                          {
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              options: _vm.optionsPaymentMethods,
                            },
                            model: {
                              value: _vm.filters.method,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "method", $$v)
                              },
                              expression: "filters.method",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                    _c(
                      "div",
                      { staticClass: "form-group mb-md-0" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Período"),
                        ]),
                        _c("flat-pickr", {
                          staticClass: "form-control",
                          attrs: {
                            config: {
                              mode: "range",
                              altInput: true,
                              dateFormat: "Y-m-d",
                              altFormat: "d/m/Y",
                              locale: "pt",
                            },
                          },
                          model: {
                            value: _vm.filters.rangeDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "rangeDate", $$v)
                            },
                            expression: "filters.rangeDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "5" } }, [
                    _c(
                      "div",
                      { staticClass: "form-group mb-md-0" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Produto"),
                        ]),
                        _c(
                          "v-select",
                          {
                            ref: "selectProduct",
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              placeholder: "Digite o título",
                              options: _vm.optionsProducts,
                            },
                            on: { search: _vm.fetchProducts },
                            model: {
                              value: _vm.filters.product,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "product", $$v)
                              },
                              expression: "filters.product",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                on: {
                                  click: function ($event) {
                                    _vm.$refs.selectProduct.open = false
                                  },
                                },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                    _c(
                      "div",
                      { staticClass: "form-group mb-md-0" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Categoria do produto"),
                        ]),
                        _c(
                          "v-select",
                          {
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              searchable: false,
                              options: _vm.optionsProductCategories,
                            },
                            model: {
                              value: _vm.filters.category,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "category", $$v)
                              },
                              expression: "filters.category",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "1" } }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-block",
                        staticStyle: { "margin-top": "20px" },
                        attrs: { type: "submit" },
                      },
                      [
                        _vm.submitedFilter
                          ? _c(
                              "div",
                              [
                                _c("b-spinner", {
                                  attrs: { label: "Loading...", small: "" },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c("feather-icon", {
                                  staticClass: "cursor-pointer cursor",
                                  attrs: { icon: "SearchIcon", size: "16" },
                                }),
                              ],
                              1
                            ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      !_vm.submitedFilter
        ? _c(
            "b-card",
            { staticClass: "mb-0", attrs: { "no-body": "" } },
            [
              _c(
                "div",
                { staticClass: "m-2" },
                [
                  _c(
                    "b-form-row",
                    { staticClass: "justify-content-between" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-none d-sm-block align-items-center justify-content-left",
                          attrs: { cols: "4", md: "2" },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              label: "title",
                              required: "",
                              options: _vm.filters.perPageOptions,
                              clearable: false,
                            },
                            on: { input: _vm.setPerPageSelected },
                            model: {
                              value: _vm.filters.perPage,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "perPage", $$v)
                              },
                              expression: "filters.perPage",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "4", offset: "md-4" } },
                        [
                          _c("b-form-input", {
                            staticClass: "d-inline-block",
                            attrs: {
                              type: "search",
                              placeholder: "Pesquisar...",
                              id: "filterInput",
                            },
                            model: {
                              value: _vm.filter,
                              callback: function ($$v) {
                                _vm.filter = $$v
                              },
                              expression: "filter",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("b-table", {
                staticClass: "position-relative",
                attrs: {
                  items: _vm.dataItems,
                  responsive: "",
                  fields: _vm.fields,
                  "primary-key": "id",
                  "show-empty": "",
                  "empty-text": "Nenhum registro encontrado",
                  filter: _vm.filter,
                  "filter-included-fields": _vm.filterOn,
                },
                on: { filtered: _vm.onFiltered },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(customer_name)",
                      fn: function (data) {
                        return [
                          _c(
                            "b-media",
                            { attrs: { "vertical-align": "center" } },
                            [
                              _c(
                                "span",
                                { staticClass: "d-block text-nowrap" },
                                [
                                  _vm._v(
                                    " " + _vm._s(data.item.customer_name) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass: "mb-0",
                                  staticStyle: { "margin-top": "4px" },
                                },
                                [
                                  _c("strong", [
                                    _c("i", {
                                      staticClass: "bi bi-credit-card-2-front",
                                    }),
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(data.item.customer_cpf) + " - "
                                  ),
                                  _c("strong", { staticClass: "text-purple" }, [
                                    _c("i", { staticClass: "bi bi-box" }),
                                    _vm._v(" " + _vm._s(data.item.items) + " "),
                                  ]),
                                  data.item.origin_name
                                    ? _c(
                                        "b-badge",
                                        {
                                          staticClass: "ml-1",
                                          attrs: { variant: "light-dark" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(data.item.origin_name) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(payment_method)",
                      fn: function (data) {
                        return [
                          _c("i", {
                            class: "bi " + data.item.payment_method_icon,
                            staticStyle: { "font-size": "1.3rem" },
                          }),
                        ]
                      },
                    },
                    {
                      key: "cell(status_name)",
                      fn: function (data) {
                        return [
                          _c(
                            "b-badge",
                            {
                              staticClass: "d-block",
                              attrs: {
                                variant: "light-" + data.item.status_class,
                              },
                            },
                            [_vm._v(" " + _vm._s(data.item.status_name) + " ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(total)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("toCurrency")(data.item.total)) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(total_discount)",
                      fn: function (data) {
                        return [
                          data.item.total_discount
                            ? _c(
                                "b-badge",
                                {
                                  staticClass: "d-block",
                                  attrs: { variant: "light-success" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("toCurrency")(
                                          data.item.total_discount
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "cell(action)",
                      fn: function (data) {
                        return [
                          _vm.$can("Pedido - Visualizar", "Comercial")
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "d-flex align-items-center",
                                  attrs: {
                                    id:
                                      "product-" +
                                      data.item.id +
                                      "-preview-icon",
                                    to: {
                                      name: "transactions-orders-preview",
                                      params: { uuid: data.item.uuid },
                                    },
                                  },
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "cursor-pointer cursor",
                                    attrs: { icon: "EyeIcon", size: "16" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2583226748
                ),
              }),
              _c("CustomPaginateTable", {
                attrs: {
                  "rows-table": _vm.rowsTable,
                  "current-page": _vm.filters.currentPage,
                  "per-page": _vm.filters.perPage,
                  "total-rows": _vm.totalRows,
                },
              }),
            ],
            1
          )
        : _c("b-card", { staticClass: "mb-0" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12 text-center" }, [
                _c(
                  "p",
                  { staticClass: "my-0 py-3 d-flex-center" },
                  [
                    _c("b-spinner", {
                      staticClass: "mr-50",
                      attrs: { small: "", variant: "primary" },
                    }),
                    _vm._v(" Buscando pedidos... "),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }